import * as React from 'react';
import { useContext, useEffect } from 'react';
import { consentConfig, gtagId } from './constants';
import ReactGA from 'react-ga4';
import { hashStringWithSHA256, isTesterEmail } from './utils';
import { AppContext } from '@/GlobalProvider/GlobalProvider';

interface GoogleTagManagerSetterProps {
  consentGiven: boolean;
}

export const GoogleTagManagerSetter: React.FC<GoogleTagManagerSetterProps> = ({
  consentGiven
}) => {
  const { email, name } = useContext(AppContext);

  const stabilizedEmail = email.replace(/[ .]/g, '');

  useEffect(() => {
    if (consentGiven) {
      ReactGA.gtag('consent', 'default', consentConfig);

      ReactGA.initialize(gtagId, {
        gaOptions: {
          user_id: hashStringWithSHA256(stabilizedEmail),
          brand_name: name
        }
      });

      ReactGA.event('logged_in');

      if (isTesterEmail(email)) {
        ReactGA.set({
          tester: true
        });
      }
    }
  }, [consentGiven]);

  return null;
};
